import { useMemo, useState } from 'react';
import { useLocalization } from '@/hooks/use-localization';
import { Button } from '@/isomorphic/components/typography/button/button';
import { StepLayout } from './step-layout';
import { ExplainerModal } from '../explainer-modal';
import { CountryType, CustomerType, KeyRoleType, PropsType } from '../../types';
import { Plus } from '../svg';
import { KeyRole } from '../key-role';
import { useFieldValidation } from '../../hooks/use-field-validation';
import { validators } from '@/hooks/use-validation';

export const StepThree = ({ fields: { keyRoles }, updateFields, getLabel, ...rest }: PropsType) => {
    const { getCountry, getLocale } = useLocalization();
    const { validate } = useFieldValidation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const validateField = (fieldName: string, value: string | boolean | string[] | Record<string, string>, isEditable: boolean) => {
        if (fieldName === 'phoneNumber') {
            return validators.phoneMobile(value as string, getLocale());
        }

        if (typeof value === 'string') {
            return validate(fieldName, value);
        }

        return Object.keys(value).every(key => {
            if (fieldName === 'governmentId' && key === 'id') {
                if (!isEditable) {
                    return true;
                }

                return validate('socialSecurityNumber', value[key as keyof typeof value]);
            }

            return validate(key, value[key as keyof typeof value]);
        });
    };

    const isSubmitDisabled = useMemo(() => {
        return !keyRoles.every(keyRole => {
            return Object.keys(keyRole).every(key => {
                if (key === 'isEditable') {
                    return true;
                }

                if (key === 'roles') {
                    return keyRole['roles'].length > 0;
                }

                return validateField(key, keyRole[key as keyof typeof keyRole], keyRole.isEditable);
            });
        });
    }, [keyRoles]);

    const updateKeyRole = (keyRole: KeyRoleType, index = 0) => {
        const updatedKeyRoles = [...keyRoles];
        updatedKeyRoles.splice(index, 1, keyRole);
        updateFields({ keyRoles: updatedKeyRoles });
    };

    const deleteKeyRole = (index = 0) => {
        const updatedKeyRoles = [...keyRoles];
        updatedKeyRoles.splice(index, 1);
        updateFields({ keyRoles: updatedKeyRoles });
    };

    const addKeyRole = () => {
        const updatedKeyRoles = [...keyRoles];
        updatedKeyRoles.push({
            roles: [],
            email: '',
            isEditable: true,
            phoneNumber: '',
            name: {
                first: '',
                last: '',
            },
            governmentId: {
                id: '',
                customerType: CustomerType.NATURAL,
                countryCode: getCountry().toLocaleUpperCase() as CountryType,
            },
        });
        updateFields({ keyRoles: updatedKeyRoles });
    };

    return (
        <>
            <StepLayout title={getLabel('stepThree')} isSubmitDisabled={isSubmitDisabled} getLabel={getLabel} {...rest}>
                <div className="-r-mt-2 r-border-b r-border-b-gray-200 r-pb-4">
                    <div className="r-prose r-mb-4" dangerouslySetInnerHTML={{ __html: getLabel('principalExplainer') }} />
                    <Button type="link" onClick={() => setIsModalOpen(true)}>
                        {getLabel('whatDoTheRolesMean')}
                    </Button>
                </div>
                {keyRoles.map((keyRole, index) => (
                    <div key={index} className="r-border-b r-border-b-gray-200 r-py-6">
                        <KeyRole
                            keyRole={keyRole}
                            getLabel={getLabel}
                            onDelete={() => deleteKeyRole(index)}
                            onUpdate={values => updateKeyRole(values, index)}
                        />
                    </div>
                ))}
                <div className="r-pt-6">
                    <Button type="secondary" onClick={addKeyRole}>
                        <span className="r-flex r-items-center r-gap-2">
                            <Plus />
                            {getLabel('addPrincipal')}
                        </span>
                    </Button>
                </div>
            </StepLayout>

            <ExplainerModal
                open={isModalOpen}
                className="r-max-w-4xl"
                onClose={() => setIsModalOpen(false)}
                header={getLabel('whatDoTheRolesMean')}
                imageSrc="/assets/images/business-loan/key.svg"
                imageSrcMobile="/assets/images/business-loan/key-mobile.svg"
            >
                <div className="r-space-y-4" dangerouslySetInnerHTML={{ __html: getLabel('principalModalExplainer') }} />
            </ExplainerModal>
        </>
    );
};
